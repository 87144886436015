let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-wecomplai-dev-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-wecomplai-dev-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://lqynrpaycfdyrg7lom2hfqyzwi.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://6kn7lslx25.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://xhhzmu46k7.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.dev.wecomplai.forwoodsafety.com",
        WEBSOCKET: "wss://s9hvljacde.execute-api.us-west-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_N4cLPqCDF",
        IDENTITY_POOL_ID: "us-west-2:7cf1534c-9935-442b-b5ff-b2636f14015b",
        USERPOOL_HOSTED_DOMAIN: "forwood-wecomplai-id-dev",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.dev.wecomplai.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.dev.wecomplai.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::328552880886:role/dev-CA"
    },
    samlProvider: {
      NAME: 'ForwoodAzure',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.13",
        HOSTNAME: "id.dev.wecomplai.forwoodsafety.com",
        ENV_NAME: "dev",
        COOKIE_DOMAIN: ".dev.wecomplai.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "1ecacdd4-04fd-4bcc-b768-9e970f7600d9",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.dev.wecomplai.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
